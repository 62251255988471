import { RemixI18Next } from 'remix-i18next'

import i18n from './i18n.config'

let remixI18next = new RemixI18Next({
  detection: {
    supportedLanguages: i18n.supportedLngs,
    fallbackLanguage: i18n.fallbackLng,
  },
  i18next: {
    ...i18n,
  },
})

export default remixI18next
