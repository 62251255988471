import i18remix from '~/locales/remix-i18next'

export function makeTitle(prefix?: string | null) {
  if (!prefix) return 'Protocol'
  return `${prefix} — Protocol`
}

export async function makeTranslatedTitle(prefixKey: string) {
  const fixedT = await i18remix.getFixedT('en')
  return makeTitle(fixedT(prefixKey))
}
